<template>
    <div class="w-100">
        <form @submit.prevent="validForm" class="row">
            <div class="col-5">
                <e-datepicker v-model="begin_date" required />
            </div>
            <div class="col-5">
                <e-datepicker v-model="end_date" required />
            </div>
            <div class="col-2 text-center">
                <button class="btn btn-primary">
                    <div class="d-inline">
                        Rechercher
                    </div>
                    <div class="d-none">
                        <font-awesome-icon class="spinner_home" :icon='["far", "spinner"]' spin />
                    </div>
                </button>
            </div>
        </form>
        <div v-if="itemsLastSynchro" class="row">
            <div class="col-12">
                <div class="box mt-3">
                    <div v-if="nbItems">
                        Nombre total de dernières connections durant cette période : {{nbItems}}
                    </div>
                    <TableStats :items="itemsLastSynchro" :columns="columnsUsers" click_field="licence_username" :click_event="onClickRow" />
                </div>
            </div>
        </div>
        <div v-if="nbNewUser" class="row">
            <div class="col-12">
                <div class="box mt-3">
                    Nombre de nouveaux utilisateurs durant cette période : {{nbNewUser}}
                </div>
            </div>
        </div>
    </div>
</template>


<script type="text/javascript">
import { EventBus } from 'EventBus';
import StatistiquesMixin from "@/mixins/Statistiques.js";
import TableStats from "@/components/TableStats.vue";

export default {
    name: "LastSynchro",
    mixins: [StatistiquesMixin],
    data () {
        return {
            begin_date: null,
            end_date: null,
            itemsLastSynchro: null,
            nbNewUser: null,
            columnsUsers : [''],
            nbItems : null,
        }
    },
    methods: {
        async validForm(e) {
            e.target[2].childNodes[0].className = "d-none"
            e.target[2].childNodes[1].className = "d-inline"

            const nbLastSynchro = await this.getNbLastSynchroBetweenDates(this.begin_date, this.end_date)
            .catch(e => {
                console.log("Erreur sur getNbLastSynchroBetweenDates", e)
            })
            this.nbItems = nbLastSynchro

            const lastSynchro = await this.getLastSynchroBetweenDates(this.begin_date, this.end_date)
            .catch(e => {
                console.log("Erreur sur getLastSynchroBetweenDates", e)
            })
            for (let user in lastSynchro) {
                let date = new Date(lastSynchro[user]["synchronisation_inserted"])
                lastSynchro[user]["synchronisation_inserted"] = ('0' + date.getDate()).slice(-2)+"/"+('0' + (date.getMonth()+1)).slice(-2)+"/"+date.getFullYear()
            }
            this.itemsLastSynchro = lastSynchro

            const nbNewUser = await this.getNewUserBetweenDates(this.begin_date, this.end_date)
            .catch(e => {
                console.log("Erreur sur getNewUserBetweenDates", e)
            })
            this.nbNewUser = nbNewUser

            e.target[2].childNodes[0].className = "d-inline"
            e.target[2].childNodes[1].className = "d-none"

        },
        onClickRow(item) {
            // Au click on swith sur l'onglet user
            EventBus.$emit('Statistiques::changeTabFromName', 'user')

            // On envoie la licencekey au composant user
            EventBus.$emit('UserStats::autoCompleteInput', item.synchronisation_licencekey)
        }
    },
    components: {
        TableStats
    }
}
</script>
